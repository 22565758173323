<template>
  <legal-rep-relations-modal-for-client-form
    v-if="visibility"
    :client-id="clientId"
    :legal-reps="legalReps"
    :visibility.sync="visibility"
    :relations="relations"
    :relations-loading="relationsLoading"
    :search-client-legal-representative="searchClientLegalRepresentative"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import LegalRepRelationsModalForClientForm
  from '@/vue_components/client/RelationManager/LegalRepRelationsModalForClientForm.vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'LegalRepRelationsModalForClientFormApp',

  components: {
    LegalRepRelationsModalForClientForm,
  },

  props: {
    setEdit: { type: String, required: true },
    askSetEdit: { type: String, required: true },
    searchClientLegalRepresentative: { type: Object, required: true },
    selector: { type: String, required: true },
  },

  data () {
    return {
      clientId: parseInt(gon.page.params.id || gon.specific.client_id),
      legalReps: [],
      relations: [],
      relationsLoading: false,
      visibility: false,
    }
  },

  watch: {
    relationsLoading (to) {
      // @ts-ignore
      $('#manage_relations').spin(to)
    },
  },

  created () {
    if (gon.specific[this.selector]) { return }
    gon.specific[this.selector] = true

    this.$pubSub.subscribe(this.setEdit, (target, { item }) => {
      this.setLegalReps()
      this.getRelations(item && item.client && item.client.id)
    })

    this.$pubSub.subscribe(this.askSetEdit, (target, { item }) => {
      this.setLegalReps()
      this.getRelations(item.id)
    })
  },

  mounted () {
    this.setLegalReps()
    this.searchClientLegalRepresentative.on('change', this.onSearchClientLegalRepresentativeChange)
    this.getRelations(this.clientId)
  },

  beforeDestroy () {
    this.searchClientLegalRepresentative.off('change', this.onSearchClientLegalRepresentativeChange)
  },

  methods: {
    open () {
      this.visibility = true
    },

    setLegalReps () {
      this.legalReps = this.getLegalReps()
    },

    onSearchClientLegalRepresentativeChange () {
      this.setLegalReps()
      const legalRepsIds = this.legalReps.map((item) => item.id)

      // удаление убранных из select2 отношений
      this.relations = this.relations.filter((item) => legalRepsIds.includes(item.legal_representative_id))

      // добавление новых из select2 отношений
      const relationsIds = this.relations.map((item) => item.legal_representative_id)
      const newRelationsIds = legalRepsIds.filter((id) => !relationsIds.includes(id))
      const newRelations = newRelationsIds.map((id) => ({ legal_representative_id: id, relation_type_id: null }))
      this.relations = [...cloneDeep(this.relations), ...cloneDeep(newRelations)]

      clientsEndpoint.setRelations(this.relations, this.searchClientLegalRepresentative).then()
    },

    getLegalReps () {
      return this.searchClientLegalRepresentative
        .select2('data')
        .map(({ id, text }) => {
          return {
            id: parseInt(id),
            name: text.split(', ')[0],
          }
        })
    },

    getRelations (clientId: number | string) {
      if (!clientId) { return }

      this.relationsLoading = true
      clientsEndpoint.getRelations(clientId)
        .then((data) => {
          this.relations = data
          clientsEndpoint.setRelations(data, this.searchClientLegalRepresentative).then() // инжектится массив в форму
        })
        .catch(Utils.reportError('client.js:getRelationsByClientId()'))
        .finally(() => { this.relationsLoading = false })
    },
  },
})
</script>
