import { getDiscountReasonsSystemNameByReasonId } from '@/vue_components/order/discount/system_discounts'

const round = Utils.moneyRound

const GenericOrderEntry = {
  init: _.noop,

  /**
   * @param builder
   * @param stop - стоп для рекурсии (Я не очень понимаю, что здесь происходит, но рекурсия не была ограничена, а человек делавший это уже ушел)
   */
  setEmptyRecursive (builder, stop = false) {
    this.setEmpty(builder.getFor(this.getKindString()))
    if (stop) { return }

    const members = this.getMembers()
    const consumables = this.getConsumables()

    members.concat(consumables)
      .forEach((member) => member.setEmptyRecursive(builder, true))
  },

  _isPriceMustBeRoundedAfterFirstRender () {
    return this.get().rounded_price === 'true'
  },

  shouldRoundOrderPrices () {
    if (!this.isSubscribedToRoundingChange) {
      this.roundPriceSwitch = $('[data-rounded-prices]')
      this.roundPriceSwitch.on('change', () => {
        this._shouldRoundOrderPrices = this.roundPriceSwitch.prop('checked')
      })
      this._shouldRoundOrderPrices = this.roundPriceSwitch.prop('checked')
      this.isSubscribedToRoundingChange = true
    }

    return this._shouldRoundOrderPrices
  },

  subscribeToVanillaRounding () {
    this.roundPriceSwitch = $('#round_order_prices')
    this.roundPriceSwitch.on('change', () => {
      this._shouldRoundOrderPrices = this.roundPriceSwitch.prop('checked')
    })
    this._shouldRoundOrderPrices = this.roundPriceSwitch.prop('checked')
  },

  isComplex () {
    return this.getKindString() === 'complex'
  },

  /**
   * @return {string}
   */
  getDiscountReasonName () {
    return getDiscountReasonsSystemNameByReasonId(this.get('discount_reason_id', 'int'))
  },

  getKind () {
    return parseInt(this.get('kind'))
  },

  /**
   * @typedef {'analysis'|'service'|'good'|'complex'} EntryKind
   * @return {EntryKind}
   */
  getKindString () {
    const kinds = { 1: 'analysis', 4: 'service', 5: 'good', 6: 'complex' }

    return kinds[this.getKind()]
  },

  hideFields () {
    Array.prototype.forEach.call(arguments, (fieldName) => {
      const field = this.field(fieldName)
      if (field) field.hide()
    })
  },

  getChildRow (selector) {
    return this
      .html()
      .nextAll(selector)
      .first()
  },

  /**
   * @return {GenericOrderEntry[]}
   */
  getMembers () {
    return this.getDescendedRows({
      start: 'f-entry-members-start',
      end: 'f-entry-members-end',
      filter: 'entry_member_row',
    })
  },

  /**
   * @return {GenericOrderEntry[]}
   */
  getConsumables () {
    return this.getDescendedRows({
      start: 'f-entry-consumables-start',
      end: 'f-entry-consumables-end',
      filter: 'entry_consumable_row',
    })
  },

  lockFromBillingChanges () {
    this._lockFromBillingChanges()
    const notifyChildren = function (elem) { elem.lockFromBillingChanges() }
    this.data('consumableSelector').hide()
    this.data('memberSelector').hide()
    this.getDataArray('members').forEach(notifyChildren)
    this.getDataArray('consumables').forEach(notifyChildren)
  },

  /**
   * This method is called multiple times when a single item is added to the list
   */
  _initDiscountSelector () {
    const row = this
    const $discountSign = row.html().find('.discount-type-sign')
    // if click handler has already been bound, return
    if ($discountSign.data('initialized')) {
      return
    }
    $discountSign.data('initialized', true)
    row.field('discount_value').data('inputmask-preset', `discount_${row.get('discount_type')}`);
    [row.field('price'), row.field('amount')].forEach(($el) => {
      $el.on('input', () => row.resetLastDiscount())
    })

    row.formatMoneyField('discount_value')
    // reset discounts first, then remember current
    row.resetLastDiscount()
    row.rememberDiscount(row.get('discount_type'))

    $discountSign.on('click', (e) => {
      const $el = $(e.target)
      const rowHtml = row.html()
      const newDiscountType = $el.data('discount-type')
      const discountValue = rowHtml.find('.f-discount-value')
      // use current discount type from row, not from html because it can be laggy
      const currentDiscountType = row.get('discount_type')
      // casting currency strings right away to numbers is possibly unsafe
      // if decimal separator is set to comma
      const discount = row.get('discount_value', 'float') || 0
      const price = row.get('sum', 'float')

      const lastDiscountNewType = row.data(`lastDiscount_${newDiscountType}`)
      const lastDiscount = row.data(`lastDiscount_${currentDiscountType}`)
      // only save current discount if it was actual click, not synthetic event
      if (e.originalEvent) {
        row.rememberDiscount(currentDiscountType)
      }

      let newDiscount
      // if discount of current type did not change,
      // restore last saved discount of the new type
      if (lastDiscount === discount && typeof lastDiscountNewType === 'number') {
        newDiscount = lastDiscountNewType
        // else calculate discount normally
      } else if (currentDiscountType !== newDiscountType) {
        if (newDiscountType === 'currency') {
          // convert from percent to currency
          newDiscount = (discount / 100 * price)
          if (this.shouldRoundOrderPrices()) {
            newDiscount = price - Math.floor(price - newDiscount)
          }
        } else {
          // convert from currency to percent
          newDiscount = (discount / price * 100)
        }
      } else {
        // set previous discount if discount type didn't change
        // so it will be formatted properly
        newDiscount = discount
      }
      // reset input mask first
      discountValue.inputmask('remove')
      discountValue[0].dataset.inputmask_initialized = undefined
      discountValue.data('inputmask-preset', 'discount_' + newDiscountType)
      // performance: setting attrs as object only does one render
      const newAttrs = {
        discount_type: newDiscountType,
      }

      // update discount value after input mask has been reset
      // new discount is always a number, so we just need to format it
      newAttrs.discount_value = this.formatForMoneyField(newDiscount, newDiscountType)
      row.set(newAttrs)

      if (newDiscountType === 'currency') {
        discountValue.data('f-currency', false)
        discountValue.removeClass('f-inputmask')
        discountValue.addClass('f-currency')
      } else {
        discountValue.removeClass('f-currency')
        discountValue.addClass('f-inputmask')
      }

      discountValue.trigger('change')
      row.rememberDiscount(newDiscountType)
    })
  },
  /**
   * Cache discount value for specified type, used to restore discount if it didn't change
   *
   * @param {string} discountType
   */
  rememberDiscount (discountType) {
    this.data(
      `lastDiscount_${discountType}`,
      +this.formatForMoneyField(this.get('discount_value', 'float'), discountType)
    )
  },

  resetLastDiscount () {
    this.data('lastDiscount_currency', null)
    this.data('lastDiscount_percent', null)
  },

  roundPrice () {
    if (this.isComplex()) return

    const discountType = this.get('discount_type')
    const newAttrs = this.recalculateMoneyAttrs({
      price: this.get('price', 'float'),
      amount: this.get('amount', 'int'),
      discount_value: this.get('discount_value', 'float'),
      discount_type: discountType,
    })

    this.setMoneyAttrs(newAttrs)

    if (discountType === 'currency') {
      this.set('discount_value',
        this.formatForMoneyField(
          Math.ceil(this.get('discount_value', 'float')),
          discountType
        )
      )

      this.html().find('.f-discount-value').trigger('change')
    }
  },

  restorePrice () {
    if (this.isComplex()) return

    const realPrice = this.data('realPrice')
    if (realPrice === undefined) {
      return fetch(Routes.entry_type_path(this.get('entry_type_id')))
        .then((res) => res.json())
        .then((res) => {
          this.updatePrice(res)
        })
        .catch((err) => {
          Notificator.error(T.errors.error_has_occurred)
          console.error(err)
        })
    }

    this.updatePrice(realPrice)
  },
  /**
   *
   * @param {{price: string, entry_type_members?: {member_id: number}[]}} prices
   */
  updatePrice (prices) {
    const price = +prices.price
    this.data('realPrice', prices)
    const newAttrs = this.recalculateMoneyAttrs({
      price,
      amount: this.get('amount', 'int'),
      discount_type: this.get('discount_type'),
      discount_value: this.get('discount_value'),
      discount_sum: this.get('discount_sum', 'float'),
    })

    this.set('price',
      this.formatForMoneyField(
        newAttrs.price
      )
    )
    this.set('final_price',
      this.formatForMoneyField(
        newAttrs.final_price
      )
    )
    this.set('sum',
      this.formatForMoneyField(
        newAttrs.sum
      )
    )
    this.set('final_sum',
      this.formatForMoneyField(
        newAttrs.final_sum
      )
    )

    if (this.isComplex()) {
      this.tryUpdateMemberPrices(prices.entry_type_members)
    } else {
      const newAttrs = this.recalculateMoneyAttrs({
        price: +prices.price,
        amount: this.get('amount', 'int'),
        discount_value: this.get('discount_value', 'float'),
        discount_type: this.get('discount_type'),
      })

      this.data('realPrice', prices)
      this.setMoneyAttrs(newAttrs)
    }
  },

  /**
   * @param {{member_id: number}[]} fetchedMembers
   */
  tryUpdateMemberPrices (fetchedMembers) {
    if (fetchedMembers) {
      const members = this.getMembers()
      members.forEach((member) => {
        const fetchedMember = fetchedMembers.find((fetched) => fetched.member_id === member.get('entry_type_id', 'int'))
        if (fetchedMember) {
          member.updatePrice({ price: fetchedMember.member.price })
        }
      })
    }
  },

  setMoneyAttrs (attrs) {
    this.set('price', this.formatForMoneyField(attrs.price))
    this.set('final_price', this.formatForMoneyField(attrs.final_price))
    this.set('sum', this.formatForMoneyField(attrs.sum))
    this.set('final_sum', this.formatForMoneyField(attrs.final_sum))
    this.set('discount_sum', attrs.discount_sum)
  },

  ensureMaxDiscount () {
    this.html().on('input', '.f-price, .f-discount-value', (e) => {
      if (this.get('discount_type') === 'currency') {
        const maxDiscount = this.get('sum', 'float')
        if (parseFloat(e.target.value) > maxDiscount) {
          this.set('discount_value', this.formatForMoneyField(maxDiscount, 'currency'))
        }
      }
    })
  },

  initDataCollection (collection, name) {
    return this.data(name, collection.reduce(function (acc, element) {
      acc[element.getIndex()] = element

      return acc
    }, {}))
  },

  showFields () {
    Array.prototype.forEach.call(arguments, (fieldName) => {
      const field = this.field(fieldName)
      if (field) field.show()
    })
  },

  _lockFromBillingChanges () {
    // cache locked for super duper performance
    if (this.data('cache-locked-from-billing-changes')) return

    this.lockFields('amount', 'discount_value')
    this.html().find('.f-nested-destroy').hide()

    this.data('locked_from_billing_changes', true)
    this.data('cache-locked-from-billing-changes', true)
  },

  getDataArray (name) {
    return Object.values(this.data(name) || {})
  },

  showContent () {
    this.html().removeClass('content-hidden')
    this.html().addClass('content-visible')
    this.showDirectChildren()
    this.data('content-visible', true)
    this.redraw()
  },

  hideContent () {
    this.html().removeClass('content-visible')
    this.html().addClass('content-hidden')
    this.hideDirectChildren()
    this.data('content-visible', false)
    this.redraw()
  },

  hideDirectChildren () {
    this.getDataArray('members').forEach(function (member) {
      member.html().hide()
      member.hideContent()
    })

    const selectors = ['consumableSelector', 'memberSelector']
    selectors.forEach((selectorName) => {
      const selector = this.data(selectorName)
      if (selector) {
        selector.hide()
      }
    })

    this.getDataArray('consumables').forEach(function (consumable) {
      consumable.html().hide()
      consumable.hideContent()
    })
  },

  getContentHtml (name) {
    const contentStart = this.html().nextAll('.f-' + name + '-content-start').first()
    const contentEnd = this.html().nextAll('.f-' + name + '-content-end').first()
    const content = contentStart.nextUntil(contentEnd)

    return contentStart.add(content).add(contentEnd)
  },

  updateContentIcon (opts) {
    const number = this.cache('content-icon-number', function () {
      return this.html().find('.toggle-content-button-number')
    })

    const iconContainer = this.cache('content-icon-container', function () {
      return this.html().find('.toggle-content-button')
    })

    if (opts.visible) {
      number.text(opts.number || 0)
    } else {
      iconContainer.hide()
    }
  },

  toggleContent () {
    if (this.data('content-visible')) {
      this.hideContent()
    } else {
      this.showContent()
    }
  },

  isConsumable () {
    return this.field('consumable') && this.get('consumable') === 'true'
  },

  /**
   * @param {{
   *   start: string,
   *   end: string,
   *   filter: string
   * }} params
   * @return {GenericOrderEntry[]}
   */
  getDescendedRows (params) {
    return this
      .html()
      .nextAll('.' + params.start)
      .first()
      .nextUntil('.' + params.end)
      .filter('.' + params.filter)
      .toArray()
      .map(function (html) {
        return $(html).nestedFieldSet()
      })
  },

  _updateContentItems (collectionName, schemaName) {
    const collection = this.getDataArray(collectionName)
    if (!collection.length) return

    const selfAmount = parseInt(this.get('amount')) || 0
    const schema = this.data(schemaName) || {}

    collection.forEach(function (element) {
      const memberAmount = schema[element.getIndex()] * selfAmount
      element.set('amount', memberAmount)
      element.updateContentItems()
    })
  },

  _updateSchema (collectionName, schemaName) {
    const divider = parseInt(this.get('amount'))
    const schema = {}

    this.getDataArray(collectionName).forEach(function (item) {
      schema[item.getIndex()] = parseInt(item.get('amount')) / divider
    })

    this.data(schemaName, schema)
  },

  noCriticalChanges (newAttrs) {
    return parseFloat(newAttrs.sum) === this.get('sum', 'float') &&
      parseFloat(newAttrs.final_sum) === this.get('final_sum', 'float') &&
      parseFloat(newAttrs.final_price) === this.get('final_price', 'float')
  },

  recalculateMoneyAttrs (attrs) {
    attrs.amount = parseFloat(attrs.amount) || 0
    attrs.price = parseFloat(attrs.price) || 0
    attrs.sum = round(attrs.price * attrs.amount)

    if (attrs.discount_type) {
      switch (attrs.discount_type) {
        case 'percent':
          attrs = this.calculateDiscountAsPercent(attrs)
          break
        case 'currency':
          attrs = this.calculateDiscountAsCurrency(attrs)
          break
        default:
          Notificator.error(T.errors.discount_error)
          console.error('wrong entry.discount_type')
      }
    }

    return attrs
  },

  maybeRoundPrices (attrs) {
    const isFirstRenderingNow = this._recalculatesCount <= 3
    const needFirstRenderRound = isFirstRenderingNow &&
      this._isPriceMustBeRoundedAfterFirstRender()

    if (this.shouldRoundOrderPrices() || needFirstRenderRound) {
      attrs.price = Math.floor(attrs.price)
      attrs.sum = attrs.amount * attrs.price
      attrs.final_price = Math.floor(attrs.final_price)
      attrs.final_sum = attrs.final_price * attrs.amount
      attrs.discount_sum = round(attrs.sum - attrs.final_sum)
    }
  },

  calculateDiscountAsPercent (attrs) {
    if (attrs.discount_disabled === 'true') {
      attrs.discount_value = 0
    } else {
      // do not show decimal digits if it is an integer
      attrs.discount_value = +Math.min(
        round(attrs.discount_value) || 0,
        100
      )
    }
    attrs.final_price = round(attrs.price - ((attrs.discount_value / 100) * attrs.price))
    attrs.final_sum = round(attrs.final_price * attrs.amount)
    attrs.discount_sum = round(attrs.sum - attrs.final_sum)

    this.maybeRoundPrices(attrs)

    return attrs
  },

  calculateDiscountAsCurrency (attrs) {
    const maxDiscount = attrs.price * attrs.amount

    if (attrs.discount_disabled === 'true') {
      attrs.discount_value = 0
    } else {
      attrs.discount_value = +Math.min(
        parseFloat(attrs.discount_value) || 0,
        maxDiscount
      )
    }
    attrs.final_sum = round(attrs.price * attrs.amount - attrs.discount_value)
    attrs.discount_sum = round(attrs.sum - attrs.final_sum)
    attrs.final_price = round(attrs.price - attrs.discount_sum / attrs.amount)

    this.maybeRoundPrices(attrs)

    return attrs
  },

  enableConsumableSelector () {
    if (this.data('consumableSelectorEnabled')) return false

    this
      .data('consumableSelector')
      .find('.f-dynamic-consumables-selector')
      .addClass('enabled')

    this.data('consumableSelectorEnabled', true)

    return true
  },

  formatAttrs (attrs) {
    return $.extend(attrs, {
      final_sum: this.formatForMoneyField(attrs.final_sum),
      price: this.formatForMoneyField(attrs.price),
    })
  },

  formatForMoneyField (value, discountType = 'currency') {
    return discountType === 'currency'
      ? parseFloat(value).toFixed(2)
      : round(value)
  },

  formatMoneyField (fieldName, discountType = this.get('discount_type')) {
    const formattedValue = this.formatForMoneyField(this.get(fieldName), discountType)
    const initialValue = this.get(fieldName)
    if (formattedValue === initialValue) return

    this.field(fieldName)[0].value = formattedValue
  },
}
export default GenericOrderEntry
