import { buildConfiglegalRepresentative } from '../components/legal_representative/modal_window_config.js'
import { buildModalConfigCustomerClient } from '../components/order/create_customer_client.js'
import { relationsManagerVue } from '@/vue_components/client/RelationManager/relations_manager'
import { createClientMatchingVue } from '@/vue_components/client/MatchingClient'
import formatter from '@/lib/formatters/formatter'
import { createLegacyClientPhoneApp } from '@/vue_apps/ClientsModule/components/LegacyClientPhone'
import {
  createPersonalDataEditorPositionAppRoot,
} from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorPositionApp'
import {
  createPersonalDataEditorSendDataToEgiszApp,
} from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorSendDataToEgisz'

FormFactory.presets.client = function (params) {
  const $submit = $('#clients_submit')

  params = $.extend(true, {
    model: 'client',
    window: $('#form_client_modal'),
    form: $('#clients_form'),
    fnItemsPath: Routes.clients_path,
    fnItemPath: Routes.client_path,
    similarItems: {
      fields: ['surname', 'name', 'second_name', 'phone'],
      url: '/clients/filter',
      target: $('#similar-clients-list'),
      render (client) {
        const birthdate = client.birthdate
          ? Utils.dateRubyToHuman(client.birthdate)
          : ''

        return $('<tr>')
          .append(`<td class="similar-clients-list-fullname">${client.fullname}</td>`)
          .append(`<td class="similar-clients-list-phone">${client.masked_phone || client.formatted_phone || ''}</td>`)
          .append(`<td class="similar-clients-list-birthdate">${birthdate}</td>`)
      },
    },
    companyField: {
      parentForm: $('#clients_form'),
      target: $('#client_company_id'),
    },
  }, params)

  let formObj

  params.form.find('.client_groups_value').select2({ width: '100%' })
  params.form.find('#client_profession_id').select2({ width: '100%' })
  params.form.find('.client_doctor_id').select2({ width: '100%' })
  params.form.find('#suggestion_client_address_search').suggestionApi({ object: 'address', scope: params.form })
  params.form.find('#client_id_card_who_issue').suggestionApi({ object: 'passport', scope: params.form })
  params.form.find('#form_client_modal').find('.modal-buttons').hide()

  const buttonCreateLegalRepresentative = params.form.find('#create_legal_representative')
  const searchClientLegalRepresentative = params.form.find('.search_client_legal_representatives')

  Utils.select2ClientAjax(searchClientLegalRepresentative, {
    multiple: true,
    containerCssClass: 'select2-client_legal_representative',
  })

  $('.select2-client_legal_representative input').addClass('lockable-ignore')

  $('.client_disc').inputmask('percentage', {
    rightAlign: false,
    radixFocus: false,
    autoUnmask: true,
  })

  new MediumEditor('#client_service_card', { // eslint-disable-line no-new
    toolbar: {
      buttons: ['bold', 'italic', 'underline'],
    },
    placeholder: {
      text: t('write_important_information_here') +
            t('about_client_improve_service'),
      hideOnClick: false,
    },
  })

  // ЕГИСЗ: отношения с законными представителями
  const relationsManager = relationsManagerVue(searchClientLegalRepresentative)
  params.form.find('#manage_relations').on('click', () => {
    relationsManager.open()
  })

  /**
   * Пабсабы для работы с законными представителями пациента
   */
  createClientMatchingVue('#vue_client_matching', searchClientLegalRepresentative)

  function initInOrder () {
    let temporaryClient = ($('#client_temporary').html().trim() === 'true')

    if (temporaryClient) {
      PubSub.emit('page.form.client.askEdit', {})
    }

    PubSub.on('page.form.client.modal.closed', function () {
      if (temporaryClient) Turbolinks.visit('/doctor_schedules')
    })

    PubSub.on('page.form.client.submitSuccess', function () {
      temporaryClient = false
    })

    PubSub.on('page.form.client.modal.canceled', function () {
      PubSub.emit('page.form.client.askReset')
    })
  }

  function appendEmkLastNumber () {
    if ($('.emk-last-number').is(':visible')) {
      $.ajax({
        url: Routes.emk_last_clients_path(),
        success (emkNumber) {
          $('.emk-last-number:visible').text(emkNumber.number || '-')
        },
        error (err) {
          console.error(err)
          $('.emk-last-number:visible').text(err.responseJSON.number || '-')
        },
      })
    }
  }

  function subscribeToEmkLastNumberChanges () {
    Services.wsSubscriptions.emkLast.connect((emkNumber) => {
      if (emkNumber) { appendEmkLastNumber() }
    })
  }

  const init = function () {
    const components = [
      FormFactory.core(params),
      FormFactory.companyField(params),
      FormFactory.inputmask(params),
    ].concat(Array.prototype.slice.call(arguments))

    formObj = $.extend.apply($, [true].concat(components))

    appendEmkLastNumber()
    PubSub.on('page.form.client.emkLastNumber.get', () => appendEmkLastNumber())
    subscribeToEmkLastNumberChanges()
  }

  const initClientFormModalWindow = () => {
    const modalWindow = $('#legal_representative_modal')
    const clientForm = modalWindow.find('#clients_form')
    const clientFormModalWindowParams = buildConfiglegalRepresentative(modalWindow, clientForm)
    const clientFormModalWindow = FormFactory.build('clientFormModalWindow', clientFormModalWindowParams)

    createLegacyClientPhoneApp('#legal_representative_modal')

    clientForm.on('ajax:success', (event, legalRepresentative) => {
      const legalFullName = formatter.formatValue(legalRepresentative, ['client:fullName'])
      const legalPhone = legalRepresentative.masked_phone ||
        '+' + Services.phoneMask.setMask(legalRepresentative.phone)

      const legalBirthday = Utils.getFormattedDate(legalRepresentative.birthdate)

      const shortInfo = [
        legalFullName,
        legalBirthday,
        legalPhone,
      ].filter((el) => el).join(', ')

      searchClientLegalRepresentative.append(new Option(shortInfo, legalRepresentative.id, true, true))
      searchClientLegalRepresentative.trigger('change')
    })

    buttonCreateLegalRepresentative.click(() => {
      clientFormModalWindow.manageWindow('clear', 'show')
      appendEmkLastNumber()
      createPersonalDataEditorPositionAppRoot('#legal_representative_modal')
      createPersonalDataEditorSendDataToEgiszApp('#legal_representative_modal', true)
    })
  }

  const createBasicClientForm = () => {
    const modalWindow = $('#create_customer_client_modal')
    const customerForm = modalWindow.find('#clients_form')
    const customerFormModalWindowParams = buildModalConfigCustomerClient(modalWindow, customerForm)
    const customerFormModalWindow = FormFactory.build('clientFormModalWindow', customerFormModalWindowParams)

    customerForm.on('ajax:success', (event, customer) => {
      Services.pubSub.emitAsync('ORDER.PAYER_SELECTOR.SET_PAYER', customer)
    })

    return customerFormModalWindow
  }

  switch (params.mode) {
    case 'treatment_plan': {
      init(FormFactory.modal(params))

      createBasicClientForm()
      createLegacyClientPhoneApp('#create_customer_client_representative_modal')

      buttonCreateLegalRepresentative.click(() => {
        createPersonalDataEditorPositionAppRoot('#create_customer_client_representative_modal')
        createPersonalDataEditorSendDataToEgiszApp('#create_customer_client_representative_modal', true)
      })

      $submit.hide()
      break
    }

    case 'order': {
      init(FormFactory.modal(params))

      const modalWindow = $('#create_customer_client_modal')
      const customerForm = modalWindow.find('#clients_form')
      const customerFormModalWindowParams = buildModalConfigCustomerClient(modalWindow, customerForm)
      const customerFormModalWindow = FormFactory.build('clientFormModalWindow', customerFormModalWindowParams)

      customerForm.on('ajax:success', (event, customer) => {
        Services.pubSub.emitAsync('ORDER.PAYER_SELECTOR.SET_PAYER', customer)
      })

      Services.pubSub.subscribe('ORDER.OPEN_MODAL_CREATE_CUSTOMER_INDIVIDUAL', () => {
        customerFormModalWindow.manageWindow('clear', 'show')
        appendEmkLastNumber()
        createPersonalDataEditorPositionAppRoot('#create_customer_client_modal')
        createPersonalDataEditorSendDataToEgiszApp('#create_customer_client_modal', true)
      })

      const modalWindowRepresentative = $('#create_customer_client_representative_modal')
      const customerFormRepresentative = modalWindowRepresentative.find('#clients_form')
      const customerFormModalWindowParamsRepresentative = buildModalConfigCustomerClient(modalWindowRepresentative, customerFormRepresentative)
      const customerFormModalWindowRepresentative = FormFactory.build('clientFormModalWindow', customerFormModalWindowParamsRepresentative)

      createLegacyClientPhoneApp('#create_customer_client_representative_modal')
      createLegacyClientPhoneApp('#create_customer_client_modal')
      createPersonalDataEditorPositionAppRoot('#form_client_modal')
      createPersonalDataEditorSendDataToEgiszApp('#form_client_modal')

      buttonCreateLegalRepresentative.click(() => {
        customerFormModalWindowRepresentative.manageWindow('clear', 'show')
        appendEmkLastNumber()
        createPersonalDataEditorPositionAppRoot('#create_customer_client_representative_modal')
        createPersonalDataEditorSendDataToEgiszApp('#create_customer_client_representative_modal', true)
      })

      customerFormRepresentative.on('ajax:success', (event, legalRepresentative) => {
        searchClientLegalRepresentative.append(new Option(legalRepresentative.short_info, legalRepresentative.id, true, true))
        searchClientLegalRepresentative.trigger('change')
      })

      $submit.hide()
      initInOrder()
      break
    }

    case 'appointment': {
      $.extend(true, params, {
        fnItemsPath: Routes.lazy_create_clients_path,
        megamodalParams: {
          btnResize: false,
        },
        similarItems: {
          target: $('.appointment-similar-clients-list'),
          watch: $('#modal_form_appointment_edit').find('#clients_form').find(
            'input.client_surname, input.client_name, input.client_second_name, input.client_phone'
          ),
        },
        form: $('#modal_form_appointment_edit').find('#clients_form'),
      })

      Services.pubSub.subscribe('APPOINTMENT.LEGAL_REPRESENTATIVE_MODAL.SUCCESS', (legalRepresentative) => {
        searchClientLegalRepresentative.append(new Option(legalRepresentative.short_info, legalRepresentative.id, true, true))
        searchClientLegalRepresentative.trigger('change')
      })

      buttonCreateLegalRepresentative.click(() => {
        Services.pubSub.emitAsync('APPOINTMENT.LEGAL_REPRESENTATIVE_MODAL.OPEN')
      })

      params.form.attr('action', Routes.lazy_create_clients_path())

      //form.find('.cient_additional_fields').hide()
      init(
        FormFactory.similarItems(params),
        FormFactory.mutable(params),
        FormFactory.lockable(params)
      )
      $submit.hide()
      break
    }

    case 'waiting_list': {
      $.extend(true, params, {
        fnItemsPath: Routes.lazy_create_clients_path,
        megamodalParams: {
          btnResize: false,
        },
        similarItems: {
          target: $('.waiting_list-similar-clients-list'),
          watch: $('#form_waiting_list_modal').find('#clients_form').find(
            'input.client_surname, input.client_name, input.client_second_name, input.client_phone'
          ),
        },
        form: $('#form_waiting_list_modal').find('#clients_form'),
      })

      params.form.attr('action', Routes.lazy_create_clients_path())

      const legalRepresentativeSelector = params.form.find('.search_client_legal_representatives')

      Services.pubSub.subscribe('WAITING_LIST.LEGAL_REPRESENTATIVE_MODAL.SUCCESS', (legalRepresentative) => {
        legalRepresentativeSelector.append(new Option(legalRepresentative.short_info, legalRepresentative.id, true, true))
        legalRepresentativeSelector.trigger('change')
      })

      params.form.find('#create_legal_representative').click(() => {
        Services.pubSub.emitAsync('WAITING_LIST.LEGAL_REPESENTATIVE_MODAL.OPEN')
      })

      init(
        FormFactory.similarItems(params),
        FormFactory.mutable(params),
        FormFactory.lockable(params)
      )
      $submit.hide()
      break
    }

    case 'new': {
      params = $.extend(true, params, {
        similarItems: { highlightSelection: false },
      })

      initClientFormModalWindow()

      init(
        FormFactory.similarItems(params),
        FormFactory.redirect(params),
        FormFactory.lockable(params)
      )

      createPersonalDataEditorPositionAppRoot('#client_info_edit_form')
      createPersonalDataEditorSendDataToEgiszApp('#client_info_edit_form', true)

      break
    }

    case 'edit': {
      initClientFormModalWindow()

      init(FormFactory.redirect(params))

      createPersonalDataEditorPositionAppRoot('#client_info_edit_form')
      createPersonalDataEditorSendDataToEgiszApp('#client_info_edit_form')

      break
    }

    default:
      init()
  }

  return formObj
}
