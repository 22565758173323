import MatchingClient from '@/vue_components/client/MatchingClient/MatchingClient'
import { extractMainSelector, SELECTORS } from '@/vue_components/client/RelationManager/relations_manager'
import { createVueApp } from '@/vue_components/create_vue_app'

export const createClientMatchingVue = (el, searchClientLegalRepresentative) => {
  if (!searchClientLegalRepresentative) {
    throw new Error('Cant find searchClientLegalRepresentative')
  }

  const selector = extractMainSelector(searchClientLegalRepresentative)
  if (!Object.values(SELECTORS).includes(selector)) { return }

  createVueApp({
    el,
    store,
    name: 'MatchingClient',
    render: (h) => h(MatchingClient, {
      props: {
        searchClientLegalRepresentative,
        selector,
      },
    }),
  })
}
