import { createVueApp } from '@/vue_components/create_vue_app'
import LegalRepRelationsModalForClientFormApp
  from '@/vue_components/client/RelationManager/LegalRepRelationsModalForClientFormApp.vue'

export const SELECTORS = {
  CLIENT_FORM: 'clients_form',
  APPOINTMENT: 'modal_form_appointment_edit',
  WAITING_LIST: 'form_waiting_list_modal',
}

export const extractConfig = (selectorName) => {
  switch (selectorName) {
    case SELECTORS.CLIENT_FORM: return {
      setEdit: 'page.form.client.setEdit',
      askSetEdit: '',
    }
    case SELECTORS.APPOINTMENT: return {
      setEdit: 'page.form.appointment.setEdit',
      askSetEdit: 'page.form.appointmentClient.mutable.askSetEdit',
    }
    case SELECTORS.WAITING_LIST: return {
      setEdit: 'page.form.waiting_list.setEdit',
      askSetEdit: 'page.form.WaitingListClient.mutable.askSetEdit',
    }
    default: return {}
  }
}

export const extractMainSelector = (jqSelector) => {
  if (!(jqSelector && jqSelector.selector)) { return null }

  const splitSelectorName = jqSelector.selector.split(' ')
  if (!splitSelectorName.length) { return null }

  return splitSelectorName[0].substring(1)
}

export const relationsManagerVue = (searchClientLegalRepresentative) => {
  if (!searchClientLegalRepresentative) {
    throw new Error('Cant find searchClientLegalRepresentative')
  }

  const selector = extractMainSelector(searchClientLegalRepresentative)
  if (!Object.values(SELECTORS).includes(selector)) { return }

  const {
    setEdit,
    askSetEdit,
  } = extractConfig(selector)

  return createVueApp({
    el: '#relations_manager',
    name: 'RelationsManager',
    render: (h) => h(LegalRepRelationsModalForClientFormApp, {
      props: {
        setEdit,
        askSetEdit,
        searchClientLegalRepresentative,
        selector,
      },
    }),
    useChildren: true,
  })
}
